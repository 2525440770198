import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import _ from "lodash";
import { toast } from "react-toastify";
import { Formik, Field, setNestedObjectValues } from "formik";
import * as Yup from "yup";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import SelectSubscription from "./SelectSubscription";
import MaskedInput from "react-text-mask";
import { formatMoney } from "../../utils/stringutils";
import { subscriptions, features } from "../../utils/subscriptionsPresets";
import { getCurrentPartnerId } from "../../utils/auth/partnerhelpers";
import moment from "moment";
import PayFieldsComponent from "./PayFieldsComponent";
import StandardField from "../common/controls/StandardField";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function UpgradeSubscriptionModal(props) {
  const [partnerUpgradeDowngrade] = useMutation(PARTNER_UPGRADE_DOWNGRADE);
  const { loading, data, error } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      id: getCurrentPartnerId(),
    },
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let payrixSucceeded = false;
  let current_suscription = subscriptions.filter(
    (f) => f.id == props?.claims?.subscription_id
  )[0].name;
  let partner = {
    current_subscription_id: props?.claims?.subscription_id,
    payrix_token_id: "",
    payrix_customer_id: "",
    payrix_last4: "",
    subscription_id: props?.claims?.subscription_id,
    subscription: current_suscription,
    terms_agree: false,
    default_time_zone: "Eastern Standard Time",
    address: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    company: "",
    payment_method: "existing",
    downgrade_flag: false,
  };
  const MySwal = withReactContent(Swal);

  if (loading) return null;

  let currentSubscriptionDetails = data.partner.current_subscription?.[0];

  if (data?.partner) {
    partner = {
      current_subscription_id: props?.claims?.subscription_id,
      payrix_token_id: "",
      payrix_customer_id: "",
      payrix_last4: data?.partner.saas_payrix_last4,
      subscription_id: props?.claims?.subscription_id,
      subscription: subscriptions.filter(
        (f) => f.id == props?.claims?.subscription_id
      )[0].name,
      terms_agree: false,
      default_time_zone: "Eastern Standard Time",
      zip: data?.partner.zip,
      address: data?.partner.address,
      city: data?.partner.city,
      state: data?.partner.state,
      email: data?.partner.email_office,
      company: data?.partner.name,
      payment_method: "existing",
    };
  }

  return (
    <Modal
      show={props.modalOpen}
      size="xl"
      backdrop="static"
      onHide={() => {
        props.setModalOpen(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={partner}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setErrorMessage("");
          let toastId = toast("Saving Changes...", {
            type: toast.TYPE.PRIMARY,
            autoClose: false,
          });

          if (
            values.subscription !== "Quotes" &&
            values.payment_method == "new"
          ) {
            if (!values.payrix_token_id || !values.payrix_customer_id) {
              setErrorMessage("Error creating payment method");
              toast.update(toastId, {
                render: "Error",
                type: toast.TYPE.ERROR,
                autoClose: 2000,
              });
              setSubmitting(false);
              return;
            }
          }

          let next_charge = null;

          if (!currentSubscriptionDetails) {
            next_charge =
              +moment().format("DD") > 28
                ? moment().add(45, "days").format("YYYY-MM-01")
                : moment().add(1, "month").format("YYYY-MM-DD");
          }
          if (
            currentSubscriptionDetails &&
            !currentSubscriptionDetails?.last_charged_date
          ) {
            next_charge = currentSubscriptionDetails?.next_charge_date;
          }
          if (
            currentSubscriptionDetails &&
            currentSubscriptionDetails?.last_charged_date
          ) {
            next_charge =
              +moment().format("DD") > 28
                ? moment().add(45, "days").format("YYYY-MM-01")
                : moment().add(1, "month").format("YYYY-MM-DD");
          }

          //console.log(next_charge);
          let upgradeResult = await partnerUpgradeDowngrade({
            variables: {
              input: {
                subscription: values.subscription,
                partner_id: getCurrentPartnerId(),
                saas_payrix_payment_method_id:
                  values.payment_method == "new"
                    ? values.payrix_token_id
                    : data?.partner?.saas_payrix_payment_method_id,
                saas_payrix_customer_id:
                  values.payment_method == "new"
                    ? values.payrix_customer_id
                    : data?.partner?.saas_payrix_customer_id,
                amount:
                  subscriptions.filter((f) => f.id == values.subscription_id)[0]
                    .numeric - (currentSubscriptionDetails?.amount ?? 0),
                next_charge_date: next_charge,
                saas_payrix_last4: values.payrix_last4,
              },
            },
          });

          if (
            upgradeResult?.errors?.length > 0 ||
            !upgradeResult?.data?.partnerUpgradeDowngrade?.success
          ) {
            payrixSucceeded = false;
            setIsFormSubmitting(false);
            setErrorMessage(
              "Error upgrading subscription : " +
                upgradeResult?.data?.partnerUpgradeDowngrade?.message
            );
            toast.update(toastId, {
              render: "Error",
              type: toast.TYPE.ERROR,
              autoClose: 2000,
            });
            setSubmitting(false);
            return;
          }

          toast.update(toastId, {
            render: "Upgrade Complete!",
            type: toast.TYPE.PRIMARY,
            autoClose: 2000,
          });
          window.location.reload();
        }}
        validationSchema={Yup.object().shape({
          terms_agree: Yup.boolean()
            .required("You must agree to the Terms of Service.")
            .oneOf([true], "You must agree to the Terms of Service."),
        })}
        render={({
          form,
          submitForm,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          isSubmitting,
          validateForm,
          setTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton style={{ display: "block" }}>
              <Modal.Title as="h5">Upgrade ProStruct360</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col sm={12} className="p-2">
                  <SelectSubscription
                    setFieldValue={setFieldValue}
                    values={values}
                    isUpgradePage={true}
                    currentSubscriptionId={values.current_subscription_id}
                    current_suscription={current_suscription}
                    user_id={props.claims.user_id}
                    is_upgrade={true}
                  />
                  {/* <span>AQII {JSON.stringify(values)}</span> */}
                </Col>

                <Col sm={12} className="p-2">
                  <div
                    style={{
                      height:
                        values.current_subscription_id !=
                          values.subscription_id && values.subscription_id > 1
                          ? "auto"
                          : "10px",
                      visibility:
                        values.current_subscription_id !=
                          values.subscription_id && values.subscription_id > 1
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    <Form.Group as={Col} md={12} className="mt-4">
                      <hr className="border-light m-0" />
                      <h5 className="my-4">
                        <strong>Payment Information</strong>
                      </h5>
                    </Form.Group>

                    <Form.Group as={Row} className="mt-3">
                      <Form.Label column sm={3} className="text-sm-right">
                        &nbsp;
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Label>Payment method:</Form.Label>

                        <select
                          name="payment_method"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.payment_method}
                          className="custom-select"
                        >
                          <option value="existing">
                            Use my current card ending in {values.payrix_last4}
                          </option>
                          <option value="new">Add New Credit Card</option>
                        </select>
                      </Col>
                    </Form.Group>

                    {values.payment_method == "new" && (
                      <>
                        <Form.Group as={Row}>
                          <Form.Label column sm={3} className="text-sm-right">
                            &nbsp;
                          </Form.Label>
                          <Col sm={9}>
                            <p>Enter your credit card details below.</p>
                            {values.current_subscription_id > 1 &&
                              data?.partner?.saas_payrix_customer_id && (
                                <p>
                                  The card you enter below will replace your
                                  current payment method on file.
                                </p>
                              )}
                          </Col>
                        </Form.Group>
                        <StandardField
                          label={
                            <span>
                              Billing Zip <span className="text-danger">*</span>
                            </span>
                          }
                          name="zip"
                          value={values.zip}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.zip}
                          touched={touched.zip}
                          fieldColumn={3}
                        ></StandardField>
                        <PayFieldsComponent
                          saas_payrix_customer_id={
                            data?.partner?.saas_payrix_customer_id
                          }
                          handleSuccess={(response) => {
                            if (payrixSucceeded) return;
                            payrixSucceeded = true;
                            console.log("handleSuccess", response);
                            setFieldValue(
                              "payrix_token_id",
                              response.data?.[0]?.token
                            );
                            setFieldValue(
                              "payrix_last4",
                              response.data?.[0]?.payment?.number
                            );
                            setFieldValue(
                              "payrix_customer_id",
                              data?.partner?.saas_payrix_customer_id
                                ? data?.partner?.saas_payrix_customer_id
                                : response.data?.[0]?.customer?.id
                            );

                            submitForm();
                          }}
                          handleFailure={(response) => {
                            console.log("handleFailure", response);
                            if (response.errors.length > 0) {
                              setErrorMessage(
                                response.errors
                                  .map((error) => error.msg)
                                  .join(", ")
                              );
                            }
                            setIsFormSubmitting(false);
                          }}
                          handleValidationFailure={(response) => {
                            console.log("handleValidationFailure", response);
                            setIsFormSubmitting(false);
                          }}
                          billingAddress={{
                            address: values.address,
                            city: values.city,
                            state: values.state,
                            zip: values.zip,
                            email: values.email,
                            company: values.name,
                            country: "USA",
                          }}
                        />
                      </>
                    )}
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} className="text-sm-right">
                        &nbsp;
                      </Form.Label>
                      <Col sm={6}>
                        {
                          // upgrading:
                          // if no currentSubscriptionDetails (using Quotes) then charge subscription amount today and renew monthly on this day (push to 1st if 29, 30, or 31)
                          // if currentSubscriptionDetails?.last_charged_date is null, then use next_charge_date and change plan (user is in trial period for paid subscription)
                          // if currentSubscriptionDetails?.last_charged_date then charge monthly amount plus difference between plan amounts today and renew monthly on this day (push to 1st if 29, 30, or 31)
                          //
                          // downgrading:
                          // immediately lose access to features
                          // if currentSubscriptionDetails?.last_charged_date is null, then use next_charge_date and change plan (user is in trial period for paid subscription)
                          // if currentSubscriptionDetails?.last_charged_date then charge monthly amount and renew monthly on this day (push to 1st if 29, 30, or 31)
                        }

                        {!currentSubscriptionDetails && (
                          <>
                            <p>
                              {`A charge of ${
                                formatMoney(
                                  subscriptions.filter(
                                    (f) => f.id == values.subscription_id
                                  )[0].numeric -
                                    (currentSubscriptionDetails?.amount ?? 0)
                                ) + " USD"
                              } will process today.`}
                            </p>
                            <p>
                              {`Your monthly fee of  ${
                                formatMoney(
                                  subscriptions.filter(
                                    (f) => f.id == values.subscription_id
                                  )[0].numeric
                                ) + " USD"
                              } will renew on ${
                                +moment().format("DD") > 28
                                  ? ` ${moment()
                                      .add(45, "days")
                                      .format(
                                        "MMMM"
                                      )} 1st and the 1st of each month after that`
                                  : "the " +
                                    moment().format("Do") +
                                    " of each month going forward."
                              }`}
                            </p>
                          </>
                        )}
                        {currentSubscriptionDetails &&
                          !currentSubscriptionDetails?.last_charged_date && (
                            <p>
                              {`Your next charge date will be on ${
                                currentSubscriptionDetails?.next_charge_date
                                  ? moment(
                                      currentSubscriptionDetails?.next_charge_date
                                    ).format("MMMM Do, YYYY")
                                  : moment()
                                      .add(14, "days")
                                      .format("MMMM Do, YYYY")
                              } for ${
                                formatMoney(
                                  subscriptions.filter(
                                    (f) => f.id == values.subscription_id
                                  )[0].numeric -
                                    (currentSubscriptionDetails?.amount ?? 0)
                                ) + " USD"
                              } and ${formatMoney(
                                subscriptions.filter(
                                  (f) => f.id == values.subscription_id
                                )[0].numeric
                              )} will renew on the ${
                                currentSubscriptionDetails?.next_charge_date
                                  ? moment(
                                      currentSubscriptionDetails?.next_charge_date
                                    ).format("Do")
                                  : moment().add(14, "days").format("Do")
                              } of each month going forward.`}
                            </p>
                          )}

                        {currentSubscriptionDetails &&
                          currentSubscriptionDetails?.last_charged_date && (
                            <>
                              {subscriptions.filter(
                                (f) => f.id == values.subscription_id
                              )[0].numeric -
                                (currentSubscriptionDetails?.amount ?? 0) >
                                0 && (
                                <p>
                                  {`A charge of ${
                                    formatMoney(
                                      subscriptions.filter(
                                        (f) => f.id == values.subscription_id
                                      )[0].numeric -
                                        (currentSubscriptionDetails?.amount ??
                                          0)
                                    ) + " USD"
                                  } will process today.`}
                                </p>
                              )}
                              <p>
                                {`Your monthly fee of ${formatMoney(
                                  subscriptions.filter(
                                    (f) => f.id == values.subscription_id
                                  )[0].numeric
                                )} USD will renew on ${
                                  +moment().format("DD") > 28
                                    ? ` ${moment()
                                        .add(45, "days")
                                        .format(
                                          "MMMM"
                                        )} 1st and the 1st of each month after that`
                                    : "the " +
                                      moment().format("Do") +
                                      " of each month going forward."
                                }`}
                              </p>
                            </>
                          )}
                      </Col>
                    </Form.Group>
                  </div>
                  <Form.Group as={Row} className="mt-2">
                    <Form.Label column sm={3} className="text-sm-right">
                      &nbsp;
                    </Form.Label>
                    <Col sm={8}>
                      <label className="switcher">
                        <Field name="terms_agree" value={values.terms_agree}>
                          {({ field, form, meta }) => (
                            <input
                              type="checkbox"
                              className="switcher-input"
                              id="terms_agree"
                              name="terms_agree"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.terms_agree}
                              checked={values.terms_agree}
                            />
                          )}
                        </Field>
                        <span className="switcher-indicator">
                          <span className="switcher-yes">
                            <span className="ion ion-md-checkmark"></span>
                          </span>
                          <span className="switcher-no">
                            <span className="ion ion-md-close"></span>
                          </span>
                        </span>
                        <span className="switcher-label">
                          I agree to the ProStruct360{" "}
                          <a
                            href="https://prostruct360.com/terms/"
                            target="_blank"
                          >
                            Terms of Service
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://prostruct360.com/privacy/"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                      {touched.terms_agree && errors.terms_agree ? (
                        <p className="mt-3 text-danger">{errors.terms_agree}</p>
                      ) : null}
                    </Col>
                  </Form.Group>
                  {errorMessage && (
                    <Form.Group as={Col} md={12} className="mt-4">
                      <h5 className="mt-4 mb-0 text-center text-danger">
                        <strong>
                          There were one or more problems with your submission:
                        </strong>
                      </h5>
                      <h5 className="mt-4 mb-0 text-center text-danger">
                        <strong>{errorMessage}</strong>
                      </h5>
                      <p className="mt-4 mb-0 text-center text-danger">
                        Please correct the issue and try again.
                      </p>
                    </Form.Group>
                  )}
                </Col>
                {values.downgrade_flag && (
                  <>
                    <Col sm={2}></Col>
                    <Col sm={8}>
                      <p className="mb-0">
                        <strong>
                          YOU MUST DISCONNECT YOUR EMAIL TO DOWNGRADE
                        </strong>
                      </p>
                      <p className="text-muted">
                        <strong>
                          You are downgrading to a subscription that does not
                          include email integration. To do this you must
                          disconnect your email account (as well as of your team
                          member's accounts if applicable). This will delete all
                          of your email data and remove all of your emails
                          currently displayed on your job card dashboards. Any
                          email you send in the future will come from
                          'nopeply@ProStruct360.com' instead of your email.
                        </strong>
                      </p>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          MySwal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            showCancelButton: true,
                            confirmButtonText: "Yes",
                          }).then((result) => {
                            if (result.value) {
                              fetch(
                                process.env.REACT_APP_SERVICES_URL +
                                  "/api/EmailManagement/RemoveEmailIntegrationCompany/" +
                                  getCurrentPartnerId()
                              )
                                .then((response) => {
                                  if (response.ok == true) {
                                    setFieldValue("downgrade_flag", false);
                                  }
                                })
                                .catch((error) => {
                                  Swal.showValidationMessage(
                                    `Request failed: ${error}`
                                  );
                                });
                            }
                          });
                        }}
                      >
                        Delete All Email Data for My Company
                      </button>
                    </Col>
                    <Col sm={2}></Col>
                  </>
                )}
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <div className="mr-auto float-left">
                <Button
                  variant="default"
                  onClick={() => props.setModalOpen(false)}
                >
                  Cancel{" "}
                  {values.subscription_id < values.current_subscription_id
                    ? "Downgrade"
                    : "Upgrade"}
                </Button>
              </div>
              <Button
                type="button"
                onClick={async () => {
                  const validationErrors = await validateForm();
                  if (Object.keys(validationErrors).length > 0) {
                    setTouched(setNestedObjectValues(validationErrors, true));
                    return;
                  }
                  setIsFormSubmitting(true);
                  if (!isFormSubmitting) {
                    if (
                      values.subscription != "Quotes" &&
                      values.payment_method == "new"
                    ) {
                      window.PayFields.submit();
                    } else {
                      submitForm();
                    }
                  }
                }}
                disabled={
                  isFormSubmitting ||
                  isSubmitting ||
                  values.subscription_id === values.current_subscription_id ||
                  values.downgrade_flag
                }
                variant={
                  values.subscription_id < values.current_subscription_id
                    ? "danger"
                    : "success"
                }
              >
                {values.subscription_id < values.current_subscription_id
                  ? "Downgrade"
                  : "Upgrade"}{" "}
                Now
              </Button>
            </Modal.Footer>
            {values.current_subscription_id != values.subscription_id &&
              values.subscription_id > 1 && (
                <Modal.Footer>
                  <div className="mr-auto float-left">
                    <p>
                      <b>ProStruct360</b> &nbsp;&#x2022;&nbsp; 515 E Crossville
                      RD, STE 490 &nbsp;&#x2022;&nbsp; Roswell, GA 30075
                      &nbsp;&#x2022;&nbsp;+ 1-800-649-7727
                    </p>
                  </div>
                </Modal.Footer>
              )}
          </Form>
        )}
      />
    </Modal>
  );
}

const QUERY = gql`
  query getPartner($id: Int!) {
    partner: partner_by_pk(id: $id) {
      id
      subscription_id
      saas_payrix_customer_id
      saas_payrix_payment_method_id
      saas_payrix_last4
      email_office
      name
      first_name
      last_name
      address
      city
      state
      zip
      current_subscription: partner_charge_monthlies(
        where: {
          _and: [
            { subscription_id: { _is_null: false } }
            { status: { _eq: "Active" } }
          ]
        }
        limit: 1
      ) {
        day_of_month
        last_charged_date
        next_charge_date
        amount
      }
    }
  }
`;

const PARTNER_UPGRADE_DOWNGRADE = gql`
  mutation partnerUpgradeDowngrade($input: PartnerUpgradeDowngradeInput!) {
    partnerUpgradeDowngrade(partnerInput: $input) {
      message
      success
    }
  }
`;
